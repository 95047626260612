import React, { PureComponent } from 'react'
import { Card } from '@blueprintjs/core'



class InstallGuide extends PureComponent {
  render() {
      return <div>
        <div style={{ margin: '20px auto' }}>
          <Card>
            <h5 style={{ marginTop: 10 }}>For iOS device</h5>
            <ol>
              <li>Open this webpage (https://enterprise.nssurge.com/install) on the iOS device with Safari.</li>
              <li><a href="itms-services://?action=download-manifest&url=https://dl.nssurge.com/ios/enterprise/manifest.plist">Click here to install</a></li>
              <li>Go back to the home screen, wait for the installation completed.</li>
              <li>After installing, go to Settings.app -> General -> Profile and trust "Kernel LLC" (a partner with Surge Networks).</li>
              <li>Open the app and input the credential.</li>
            </ol>
          </Card>
        </div>
        <div style={{ margin: '20px auto' }}>
          <Card>
            <h5 style={{ marginTop: 10 }}>For macOS Device</h5>
            <ol>
              <li>Download Surge Mac from <a href="https://dl.nssurge.com/mac/v5/Surge-latest.zip">here</a> (same as the personal version).</li>
              <li>If the device is already activated with a personal license, please deactivate it first.</li>
              <li>Open the app, choose 'Already have a license' -&gt; 'Enterprise License' and input the credential.</li>
            </ol>
          </Card>
        </div>
      </div>
  }
}

export default InstallGuide
